<template>
  <b-modal @hide="hideModal"
           ref="driversTable"
           size="xl" centered @ok="okTapped"
           id="drivers" title="Select driver(s)">

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <p class="text-muted font-13 mb-4"></p>
                <div class="row mb-md-2">

                  <div class="col-sm-12 col-md-4">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Actions -->
                  <div class="col-sm-12 col-md-4">
                    <p>
                      <b-button size="sm" class="mr-2" @click="selectAllRows">Select all</b-button>
                      <b-button size="sm" @click="clearSelected">Clear selected</b-button>
<!--                      <b-button size="sm" @click="selectThirdRow">Select 3rd row</b-button>-->
<!--                      <b-button size="sm" @click="unselectThirdRow">Unselect 3rd row</b-button>-->
                    </p>
                  </div>
                  <!-- End search -->

                  <!-- Search -->
                  <div class="col-sm-12 col-md-4">
                    <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                      :items="tableData"
                      :fields="fields"
                      v-model="currentItems"
                      ref="selectableTable"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      selectable
                      @row-selected="onRowSelected"
                  >

                    <template v-slot:cell(name)="row">
                      {{row.item.name}} {{row.item['other_names']}}
                    </template>

                    <template v-slot:cell(checkbox)="row">
                      <b-form-checkbox  @click.stop v-model="row['rowSelected']" @change="onCheckBoxChanged(row)"></b-form-checkbox>
                    </template>

                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

  </b-modal>
</template>

<script>

export default {
  name: "share-driver-list",
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      currentItems: [],
      selectedItems: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
          'checkbox',
        {
          key: "name",
          sortable: true,
        },
        {
          key: "phone",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        }
      ],
    };
  },
  computed: {
    tableData() {
      return  this.$store.getters.GET_DRIVERS;
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    hideModal() {
    },
    okTapped(){
      console.log('okTapped selectedRows',this.selectedItems)
     this.$emit('on-drivers-selected', this.selectedItems)
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(selectedRows){
      console.log('selectedRows',selectedRows)
      this.selectedItems = selectedRows;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onCheckBoxChanged(row){
      const index = row['index']
      if(this.$refs.selectableTable.isRowSelected(index)){
        this.$refs.selectableTable.unselectRow(index)
      }else{
        this.$refs.selectableTable.selectRow(index)
      }
    }
  },
  created() {
    this.$store.dispatch('getDrivers', {limit: 1000, status: ''});
    // Set the initial number of items
  }

}
</script>

<style scoped>

</style>