<template>
  <div>
    <form action="#" @submit.prevent="handleSubmit">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Assign {{car ? car['name'] : 'car'}} to driver</h4>

          <b-card class="mt-2 mb-2">
            <ul>
              <li>2021 data will be uploaded via excel</li>
              <li>All drivers already renting the car outside the system will be booked afresh for 2022</li>
              <li>Kilometers on the car will be the km at the time of booking</li>
              <li>So the remaining km for a driver already renting the car in 2021 will be converted to days and that's what will be set on the system </li>
            </ul>
          </b-card>

          <form action="#">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label > Select driver <span class="text-danger">*</span></label>
                  <div class="input-group input-group-merge">
                    <template v-if="driver">
                      <input type="text" class="form-control" placeholder="Enter value here" v-model=" driver['name'] + ' ' + driver['other_names'] " disabled/>
                    </template>
                    <template v-else>
                      <input type="text" class="form-control" placeholder="Enter value here" disabled/>
                    </template>

                    <div class="input-group-append" @click="$bvModal.show('drivers')">
                      <div class="input-group-text bg-danger" style="cursor: pointer">
                        <span class="fa fa-car text-white"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label >Pickup date</label>
                  <b-form-datepicker v-model="pickupDate" :date-disabled-fn="dateDisabled" locale="en"></b-form-datepicker>
<!--                  <input type="date" id="pickupDate" v-model="pickupDate" class="form-control" required>-->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>To</label>
                  <b-form-datepicker v-model="returnDate" :date-disabled-fn="dateDisabled" locale="en"></b-form-datepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pickupTime">Pickup up time</label>
                  <input type="time" id="pickupTime" v-model="pickupTime"  class="form-control" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="returnTime">Return time</label>
                  <input type="time" id="returnTime" v-model="returnTime" class="form-control" required>
                </div>
              </div>
            </div>
            <div class="row">

              <!--            <div class="col-md-6">-->
              <!--              <div class="form-group">-->
              <!--                <label for="cost">Cost of car</label>-->
              <!--                <input type="text" id="cost" value="cost" v-model="rentalCost['cost']"  class="form-control" required disabled>-->
              <!--              </div>-->
              <!--            </div>-->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cost">Cost of car <small>Click the blue button to get cost</small></label>
                  <div class="input-group input-group-merge">
                    <input type="text" id="cost" value="cost" v-model="cost"  class="form-control" disabled>
                    <div class="input-group-append" @click="fetchCost">
                      <div class="input-group-text bg-info" style="cursor: pointer">
                        <span class="fas fa-redo-alt text-white"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="duration">Duration <small>Click the blue button to estimated duration</small></label>
                  <div class="input-group input-group-merge">
                    <input type="text" id="duration" v-model="duration" class="form-control" disabled>
                    <div class="input-group-append" @click="fetchCost">
                      <div class="input-group-text bg-info" style="cursor: pointer">
                        <span class="fa fa-redo text-white"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="form-group">
                  <label >Initial payment amount in €</label>
                  <input type="text" v-model="initialPaymentAmount" class="form-control" required disabled>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="form-group">
                  <label >Deadline for payment</label>
                  <input type="text" :value="initialPaymentDeadLine" class="form-control" required disabled>
                </div>
              </div>


              <div class="col-md-12 mt-2">
                <div class="form-group">
                  <b-card v-if="initialPaymentComment !== ''">
                    <span class="text-danger">
                      {{initialPaymentComment}}
                    </span>
                  </b-card>
                </div>
              </div>

<!--              <div class="col-md-12">-->
<!--                <hr>-->
<!--              </div>-->

              <div class="col-md-6 mt-2">
                <div class="form-group">
                  <label>Has the driver already made initial payment on base cost ? </label>
                  <b-form-checkbox v-model="initialRentalFeePaid" switch>
                    {{initialRentalFeePaid ? 'Yes' : 'No'}}
                  </b-form-checkbox>
                </div>
              </div>

              <div class="col-md-6 mt-2" >
                <div class="form-group">
<!--                  <b-form-checkbox v-model="sendInitialPaymentInvoiceToDriver" switch>-->
<!--                    Send initial base cost to driver<br>-->
<!--                  </b-form-checkbox>-->
                  <template v-if="initialRentalFeePaid">
                    <span class="text-danger">No invoice will be sent to driver. However the initial payment will be recorded. Thus the system will assume the driver paid outside the system (offline)</span>
                  </template>
                  <template v-else>
                    <span class="text-success">An invoice will be generated for the driver with a payment deadline as stated above</span>
                  </template>
                </div>
              </div>
              <div class="col-md-6 mt-2" v-if="initialRentalFeePaid">
                  <div class="form-group">

                  </div>

              </div>


              <div class="col-md-12">
                <hr>
              </div>

              <div class="col-md-6 mt-2">
                <div class="form-group">
                  <label for="initialRiskOfDamage">Risk of damage in €</label>
                  <input type="text" id="initialRiskOfDamage" v-model="initialRiskOfDamage" class="form-control" required disabled>
                </div>

              </div>

              <div class="col-md-6 mt-2">
                <div class="form-group">
                  <label for="riskOfDamage">Gross Risk of damage in €</label>
                  <input type="text" id="riskOfDamage" v-model="grossRiskOfDamage" class="form-control" required disabled>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="damageFeePerDay">Recurring risk of damage in €</label>
                  <input type="text" id="damageFeePerDay" v-model="damageFeePerDay" class="form-control" disabled>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Toggle to reduce risk of damage</label>
                      <b-form-checkbox v-model="hasInitialRiskOfDamageFee" switch @change="initialRiskOfDamageControlChanged">{{ hasInitialRiskOfDamageFee ? 'Yes' : 'No'}}</b-form-checkbox>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Add initial risk of damage fee <span class="text-info">(if applicable)</span> to invoice</label>
                      <b-form-checkbox v-model="addInitialRiskOfDamageFeeToInvoice" switch @change="addInitialRiskOfDamageToInvoiceControlChanged">{{ addInitialRiskOfDamageFeeToInvoice ? 'Yes' : 'No'}}</b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mt-2">
                <router-link to="/rentals/general-configurations"> Click here to change risk of damage configuration</router-link>
              </div>

              <div class="col-md-6">
                <b-card v-if="rodAdminMessage !== ''">
                  <template>
                    {{rodAdminMessage}}
                  </template>
                </b-card>
                <b-card v-if="hasInitialRiskOfDamageFee && addInitialRiskOfDamageFeeToInvoice">
                  <template>
                    {{'£ ' + initialRiskOfDamage + ' will be added to the invoice of the initial rental cost generated'}}
                  </template>
                </b-card>
              </div>

              <div class="col-md-12">
                <hr>
              </div>


            </div>
          </form>

          <div class="row">
            <div class="col-md-12 text-center">
              <button class="btn btn-dark" type="submit">Assign</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <shared-drivers @on-drivers-selected="onDriverSelected" ></shared-drivers>
  </div>
</template>

<script>
import SharedDrivers from '../drivers/share-driver-list'
import {set} from "firebase/database";
import {confirm} from "../../../../utils/functions";
import {rentalsService} from "../../../../apis/rentals.service";
import moment from "moment";

export default {
  name: "assign-car",
  components: {
    SharedDrivers
  },
  data() {
    return {
      hasInitialRiskOfDamageFee: false,
      addInitialRiskOfDamageFeeToInvoice: true,
      riskOfDamageComment: '',
      initialRentalFeePaid: false,
      pickupDate: '',
      pickupMinDate: '',
      nextAvailableDateDisplay: '',
      rodAdminMessage: '',
      pickupMinTime: '',
      returnDate: '',
      pickupTime: '',
      returnTime: '',
      initialPaymentAmount: '',
      initialPaymentStartWeek: '',
      initialPaymentDeadLine: '',
      initialPaymentComment: '',
      grossRiskOfDamage: '',
      initialRiskOfDamage: '',
      damageFeePerDay: '',
      driver: null,
      cost: '',
      duration: '',
      bookedDates: []
    }
  },
  computed:{
    rentalCost(){
      return this.$store.getters.GET_RENTAL_COST;
    },
    rentalSetup(){
      return this.$store.getters.GET_RENTAL_SETUPS;
    },
    car(){
      return this.$store.getters.GET_SELECTED_CAR;
    },
  },
  watch: {
    rentalCost: function (payload) {
      console.log('rentalCost changed', payload)
      this.cost = payload['cost']
      this.duration = payload['duration']
      const initialPayment = payload['initialPayment']
      this.initialPaymentAmount = initialPayment['amount']
      this.initialPaymentDeadLine = initialPayment['deadlineForInvoiceDisplay']
      this.initialPaymentComment = initialPayment['commentForAdmin']
      this.riskOfDamageComment = initialPayment['rodDisplayMessageAdmin']
      this.initialRiskOfDamage = initialPayment['rodValue'];
      this.rodAdminMessage = initialPayment['rodDisplayMessageAdmin'];
    },
    rentalSetup: function (setup){
      this.grossRiskOfDamage = setup['gross_risk_of_damage'];
      this.damageFeePerDay = setup['recurring_price_per_day'];
      this.initialRiskOfDamage = setup['gross_risk_of_damage'];
    },
    car: function (car){
      console.log(car)
      // this.pickupMinDate = car['pickupMinDate']
      // this.nextAvailableDateDisplay = car['nextAvailableDateDisplay']
      // this.pickupMinTime = car['pickupMinTime']
    }
  },
  methods: {

    dateDisabled(ymd, date){
      // console.log('ymd', ymd)
      // console.log('date', date)
      // console.log('booked dates', this.bookedDates)
      //return true; // true means disable, false means enable
      const index = this.bookedDates.findIndex(e => {
        return e['date'] === ymd;
      });

      const yesterday = moment().subtract(1, 'days');
      const currentDate = moment(ymd)

      if(currentDate <= yesterday){
        return true;
      }

      return index !== -1;
      // return this.bookedDates.indexOf(ymd) !== -1;
    },

    onDriverSelected(drivers){
      console.log('selected drivers', drivers)
      if(drivers.length === 1){
        this.driver =  drivers[0];
      }else if(drivers.length < 1) {
        this.$store.dispatch('error', { message: "Please select a driver", showSwal: true})
      }else{
        this.$store.dispatch('error', { message: "Please select only one driver", showSwal: true})
      }

    },

    fetchCost(){
        if(this.pickupDate === ''){
          this.$store.dispatch('error', { message: "Please select the pick up date", showSwal: true})
          return;
        }

        if(this.returnDate === ''){
          this.$store.dispatch('error', { message: "Please select the date of return", showSwal: true})
          return;
        }

        if(this.pickupTime === ''){
          this.$store.dispatch('error', { message: "Please select the pick up time", showSwal: true})
          return;
        }

        if(this.returnTime === ''){
          this.$store.dispatch('error', { message: "Please select the estimated time of return", showSwal: true})
          return;
        }

      if (!this.driver){
        this.$store.dispatch('error', {message: 'Please select a driver', showSwal: true})
        return;
      }

      if (!this.car){
        this.$store.dispatch('error', {message: 'car not selected', showSwal: true})
        return;
      }


        const payload = {
          'from': this.pickupDate,
          'to': this.returnDate,
          'pickupTime': this.pickupTime,
          'returnTime': this.returnTime,
          'driverId': this.driver['id'],
          'carId': this.car['id'],
          'useReducedRiskOfDamage': this.hasInitialRiskOfDamageFee
        }

        console.log('payload', payload)
        this.$store.dispatch('calculateRentalCost', payload)
    },

    initialRiskOfDamageControlChanged(value){
      console.log(value)
      // if(value){
      //   this.grossRiskOfDamage = this.rentalSetup['gross_risk_of_damage'];
      //   this.damageFeePerDay = this.rentalSetup['recurring_price_per_day'];
      //   this.initialRiskOfDamage = this.rentalSetup['reduced_risk_of_damage'];
      // }else{
      //   this.grossRiskOfDamage = this.rentalSetup['gross_risk_of_damage'];
      //   this.damageFeePerDay = this.rentalSetup['recurring_price_per_day'];
      //   this.initialRiskOfDamage = this.rentalSetup['gross_risk_of_damage'];
      // }
      this.fetchCost();



    },

    fetchBookedDates(){
      this.$store.dispatch('showLoader')
      const carId = this.$route.params.id;
      rentalsService.fetchBookedDates(carId).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.bookedDates = data['extra'];

      })

    },

    addInitialRiskOfDamageToInvoiceControlChanged(value){
      // if(value){
      //   this.riskOfDamageComment = '£ ' + this.initialRiskOfDamage + ' will be added to the initial rental cost generated'
      // }else{
      //   this.riskOfDamageComment = '£ ' + this.initialRiskOfDamage + ' will be added to the initial rental cost generated'
      // }
    },


    handleSubmit(){

        if (!this.driver){
          this.$store.dispatch('error', {message: 'Please select a driver', showSwal: true})
          return;
        }

        const driverName = this.driver['name'] + ' ' + this.driver['other_names']
        confirm('Rent car to ' + driverName, () => {

          const payload = {
            'from': this.pickupDate,
            'to': this.returnDate,
            'pickupTime': this.pickupTime,
            'returnTime': this.returnTime,
            'carId': this.$route.params.id,
            'driverId': this.driver['id'],
            'initialRentalFeePaid': this.initialRentalFeePaid,
            'hasReducedRiskOfDamage': this.hasInitialRiskOfDamageFee,
            'addInitialRiskOfDamageFeeToInvoice': this.addInitialRiskOfDamageFeeToInvoice
          }

          console.log('payload', payload)

          this.$store.dispatch('submitRentRequest', payload).then(() => {
                this.hasInitialRiskOfDamageFee = false;
                this.initialRentalFeePaid =  false;
                this.addInitialRiskOfDamageFeeToInvoice = true;
                this.pickupDate =  '';
                this.returnDate =  '';
                this.pickupTime =  '';
                this.returnTime =  '';
                this.driver =  null;
                this.cost =  '';
                this.duration =  '';

                this.grossRiskOfDamage = this.rentalSetup['gross_risk_of_damage'];
                this.damageFeePerDay = this.rentalSetup['recurring_price_per_day'];
                this.initialRiskOfDamage = this.rentalSetup['gross_risk_of_damage'];

                this.initialPaymentAmount = ''
                this.initialPaymentDeadLine = ''
                this.initialPaymentComment = ''
          })

        })


    },


  },
  created() {
    this.$store.dispatch('getRentalConfigs');
    const carId = this.$route.params.id;
    this.$store.dispatch('getCarDetail', carId)
    this.fetchBookedDates()

  }
}
</script>

<style scoped>

</style>